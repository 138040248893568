import React from 'react'
import { css } from '@emotion/core'
import styled from '@emotion/styled'
import Layout from '../components/layout'

export default function NotFoundPage() {
  return (
    <Layout>
      <Div>
        <h1>ERROR 404</h1>
        <p>The page you requested does not exist.</p>
      </Div>
    </Layout>
  )
}

const Div = styled.div(props => {
  return css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 35vh;
  `
})
